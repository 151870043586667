var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.wasiat")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_wasiat,
      expression: "form.allow_wasiat"
    }],
    attrs: {
      "type": "radio",
      "name": "radios1",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.allow_wasiat, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "allow_wasiat", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_wasiat,
      expression: "form.allow_wasiat"
    }],
    attrs: {
      "type": "radio",
      "name": "radios1",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.allow_wasiat, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "allow_wasiat", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.hibah")))]), _c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.hibah-corporate")))]), _c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_hibah,
      expression: "form.allow_hibah"
    }],
    attrs: {
      "type": "radio",
      "name": "radios3",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.allow_hibah, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "allow_hibah", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_hibah,
      expression: "form.allow_hibah"
    }],
    attrs: {
      "type": "radio",
      "name": "radios3",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.allow_hibah, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "allow_hibah", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]), _vm.preference.allow_hibah == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 mb-3"
  }, [_c('hr'), _c('h6', [_vm._v(_vm._s(_vm.$t("preference.corporate-trust")))])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("is-add-prop-allowed")) + "?")]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_allow_moveable_property,
      expression: "form.hibah_allow_moveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios4",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_allow_moveable_property, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_allow_moveable_property", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_allow_moveable_property,
      expression: "form.hibah_allow_moveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios4",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_allow_moveable_property, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_allow_moveable_property", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.add-immovprop")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_allow_immoveable_property,
      expression: "form.hibah_allow_immoveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios5",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_allow_immoveable_property, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_allow_immoveable_property", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_allow_immoveable_property,
      expression: "form.hibah_allow_immoveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios5",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_allow_immoveable_property, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_allow_immoveable_property", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.movprop")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_moveable_property,
      expression: "form.hibah_single_moveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios6",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_moveable_property, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_moveable_property", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_moveable_property,
      expression: "form.hibah_single_moveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios6",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_moveable_property, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_moveable_property", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.immovprop")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_immoveable_property,
      expression: "form.hibah_single_immoveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios7",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_immoveable_property, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_immoveable_property", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_immoveable_property,
      expression: "form.hibah_single_immoveable_property"
    }],
    attrs: {
      "type": "radio",
      "name": "radios7",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_immoveable_property, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_immoveable_property", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("is-add-guardian-allowed")) + "?")]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_caretaker,
      expression: "form.hibah_single_caretaker"
    }],
    attrs: {
      "type": "radio",
      "name": "radios8",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_caretaker, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_caretaker", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_caretaker,
      expression: "form.hibah_single_caretaker"
    }],
    attrs: {
      "type": "radio",
      "name": "radios8",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_caretaker, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_caretaker", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("is-add-beneficiaries-allowed")) + "?")]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_receiver,
      expression: "form.hibah_single_receiver"
    }],
    attrs: {
      "type": "radio",
      "name": "radios9",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_receiver, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_receiver", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_receiver,
      expression: "form.hibah_single_receiver"
    }],
    attrs: {
      "type": "radio",
      "name": "radios9",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_receiver, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_receiver", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.heir")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_heir,
      expression: "form.hibah_single_heir"
    }],
    attrs: {
      "type": "radio",
      "name": "radios10",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_heir, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_heir", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_single_heir,
      expression: "form.hibah_single_heir"
    }],
    attrs: {
      "type": "radio",
      "name": "radios10",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.hibah_single_heir, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "hibah_single_heir", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.saham-type")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.corporate_saham_account_type,
      expression: "form.corporate_saham_account_type"
    }],
    attrs: {
      "type": "radio",
      "name": "radios11",
      "value": "No. Akaun"
    },
    domProps: {
      "checked": _vm._q(_vm.form.corporate_saham_account_type, "No. Akaun")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "corporate_saham_account_type", "No. Akaun");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("acc-no")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.corporate_saham_account_type,
      expression: "form.corporate_saham_account_type"
    }],
    attrs: {
      "type": "radio",
      "name": "radios11",
      "value": "No. Perjanjian"
    },
    domProps: {
      "checked": _vm._q(_vm.form.corporate_saham_account_type, "No. Perjanjian")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "corporate_saham_account_type", "No. Perjanjian");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("agreement-no")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.corporate_saham_account_type,
      expression: "form.corporate_saham_account_type"
    }],
    attrs: {
      "type": "radio",
      "name": "radios11",
      "value": "No. Polisi"
    },
    domProps: {
      "checked": _vm._q(_vm.form.corporate_saham_account_type, "No. Polisi")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "corporate_saham_account_type", "No. Polisi");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("policy-no")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.corporate_saham_account_type,
      expression: "form.corporate_saham_account_type"
    }],
    attrs: {
      "type": "radio",
      "name": "radios11",
      "value": "No. Pendaftaran Syarikat"
    },
    domProps: {
      "checked": _vm._q(_vm.form.corporate_saham_account_type, "No. Pendaftaran Syarikat")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "corporate_saham_account_type", "No. Pendaftaran Syarikat");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("co-regno")) + " ")])])])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.trust-property")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_fixed_saham_type,
      expression: "form.hibah_fixed_saham_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "harta tetap"
    },
    domProps: {
      "value": _vm.form.hibah_fixed_saham_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "hibah_fixed_saham_type", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-payments")))]), _c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_payment,
      expression: "form.allow_payment"
    }],
    attrs: {
      "type": "radio",
      "name": "radios12",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.allow_payment, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "allow_payment", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])]), _c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_payment,
      expression: "form.allow_payment"
    }],
    attrs: {
      "type": "radio",
      "name": "radios12",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.form.allow_payment, "2")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "allow_payment", "2");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])])])])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updatePreference
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.isSubmit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }